<ev-modal-dialog
  [$title]="dialogTitle"
  [$subtitle]="document.name"
  [$stretchMode]="true"
  (closeDialog)="closeDialog.emit()">
  <form [formGroup]="accessRequestForm" (ngSubmit)="onSubmit()">
    <atom-textarea
      [label]="descLabel"
      [placeholder]="descPlaceholder"
      rows="4"
      atomsControl
      formControlName="description"></atom-textarea>
    @if (isDocumentPCF) {
      <div>
        <div data-cy="carbon-footprint-checkbox" class="custom-checkbox-line">
          <atom-checkbox
            data-cy="pcf-terms-checkbox"
            class="inline-checkbox"
            atomsControl
            formControlName="agreedToTerms"
            label="">
          </atom-checkbox>
          <p class="inline-label">
            <span>
              After receiving the PCF document by mail I will not share, copy or
              manipulate the document. I have read and I agree to the
            </span>
            <a [routerLink]="'/products/pcf'" href="" target="_blank"
              ><span>terms of use</span></a
            >
          </p>
        </div>
      </div>
    }
    <div class="action-buttons mt-4 grid grid-flow-col sm:justify-end">
      <atom-button secondary (click)="closeDialog.emit()" class="mr-6">
        <span>Cancel</span>
      </atom-button>
      <atom-button-submit [disabled]="submitIsDisabled" (click)="onSubmit()"
        ><span>Request Access</span>
        @if (pendingStatus) {
          <atom-progress-spinner inline class="ml-2"></atom-progress-spinner>
        }
      </atom-button-submit>
    </div>
  </form>
</ev-modal-dialog>
<router-outlet></router-outlet>
