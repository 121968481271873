<div class="product-info flex flex-col md:flex-row">
  <div class="base-info p-6 sm:p-8 flex-1">
    <div class="inline-block">
      <ev-back-link (click)="this.navigateBack.emit(product)"></ev-back-link>
    </div>
    <div
      class="title-line mt-8 flex gap-4 flex-wrap justify-between items-center">
      <div class="title" data-cy="product-name" [evShimmerEffect]="loading">
        {{ product.name }}
      </div>
      <div
        class="buttons-container"
        *ngTemplateOutlet="
          actionButtons;
          context: { $implicit: product }
        "></div>
    </div>
    <div class="description mt-4" [evShimmerEffect]="loading">
      {{ product.description }}
    </div>
  </div>
  @if (product.articles.length > 0) {
    <div class="article-selector p-6 sm:p-8 flex-1">
      <dp-article-selector
        [product]="product"
        [loading]="loading"
        data-cy="article-selector"
        (requestPrice)="requestPrice.emit(product)"
        (requestSample)="requestSample.emit(product)"></dp-article-selector>
    </div>
  }
</div>

<!-- Templates -->
<ng-template #actionButtons let-product>
  @if (!product.articles.length) {
    <div class="action-buttons flex gap-4 flex-wrap">
      <atom-button (click)="requestPrice.emit(product)">
        <span>Request Price</span>
      </atom-button>
      <atom-button
        secondary
        (click)="requestSample.emit(product)"
        data-cy="request-sample-button">
        <span i18n>Request Sample</span>
      </atom-button>
    </div>
  }
</ng-template>
