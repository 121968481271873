<div
  class="document-row lg:grid gap-3"
  [evColumnLayout]="columnLayout"
  [ngClass]="[document.accessibility.toLocaleLowerCase()]">
  <div
    class="title-col flex items-center select-none"
    [ngClass]="{
      'cursor-pointer': this.document.accessibility !== 'PENDING',
    }"
    (click)="onClickAction()">
    <div class="image flex items-center shrink-0">
      @if (document.flagIconUrl) {
        <img class="shrink-0 w-5" [src]="document.flagIconUrl" />
      }
      @if (!document.flagIconUrl) {
        <atom-icon class="shrink-0" [iconId]="titleIcon"></atom-icon>
      }
    </div>
    {{ document.name }}
  </div>

  <!-- We hide the following columns on mobile -->
  <div class="type-col max-lg:hidden">
    {{ document.documentType }}
  </div>

  <div class="region-col max-lg:hidden">
    {{ document.region }}
  </div>

  <div class="language-col max-lg:hidden">
    {{ languagesString }}
  </div>

  <div class="actions-col max-lg:hidden">
    <ng-container *ngTemplateOutlet="actionLink"></ng-container>
  </div>

  <!-- Mobile details block -->
  <div class="mobile-details-block lg:hidden flex justify-between items-center">
    {{ languagesString }}
    <ng-container *ngTemplateOutlet="actionLink"></ng-container>
  </div>
</div>

<ng-template #actionLink>
  <div
    class="flex items-center select-none"
    [ngClass]="{
      'cursor-pointer': this.document.accessibility !== 'PENDING',
    }"
    (click)="onClickAction()">
    {{ actionText }}
    <atom-icon class="ml-2" [iconId]="actionIcon"></atom-icon>
  </div>
</ng-template>
