<div
  class="attributes-card bg-white grid grid-1"
  #attributesCard
  [ngClass]="{
    collapsed: attributesCard.offsetHeight > 300 && !showMore,
  }">
  <!-- Comes later: -->
  <!-- <div class="product-properties p-8">
      <div class="title">Product Properties</div>
    </div> -->
  <div class="property-list p-8 max-sm:p-6">
    <div class="title flex items-center gap-2">
      <span [evShimmerEffect]="loading">Sustainability Benefits</span>
      <a
        href="https://www.basf.com/global/en/who-we-are/sustainability/we-drive-sustainable-solutions/sustainable-solution-steering.html"
        target="_blank"
        class="cursor-pointer flex items-center">
        <atom-tooltip position="right" class="z-10 flex items-center">
          <atom-icon
            slot="invoker"
            [iconId]="'atom:action:info'"
            class="filter-tooltip-icon mx-1"
            [evShimmerEffect]="loading"></atom-icon>

          <div slot="content">
            Click on the link to learn more how this information is calculated.
          </div>
        </atom-tooltip>
      </a>
    </div>
    <div class="attribute-list mt-8 grid gap-6">
      @for (attribute of product.sustainabilityAttributes; track attribute) {
        <div class="row flex gap-4" [evShimmerEffect]="loading">
          <img
            class="w-[24px]"
            ngSrc="assets/sustainability/{{ attribute.category }}.svg"
            width="24"
            height="24"
            alt="" />
          <div class="attribute-name">
            {{ attribute.name }}
          </div>
        </div>
      }
    </div>
  </div>
  @if (product.sustainabilityAttributes.length > 3) {
    <div
      class="show-more-row p-2 hidden gap-2 justify-center items-center font-bold cursor-pointer hover:underline"
      (click)="showMore = true">
      <span class="text" [evShimmerEffect]="loading">Show more</span>
      <atom-icon
        iconId="atom:core:arrow_down"
        [evShimmerEffect]="loading"></atom-icon>
    </div>
  }
</div>
